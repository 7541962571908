import React from 'react';
import {
    Show,
    Tab,
    TabbedShowLayout,
    TopToolbar,
    EditButton,
} from 'react-admin';
import OrderTitle from './OrderTitle';
import RequestShippingButton from './RequestShippingButton';
import PrintShippingLabels from './DownloadShippingLabelsButton';
import DetailsTab from './Tabs/DetailsTab';
import MainFieldsTab from './Tabs/MainFieldsTab';
import ShippingDataTab from './Tabs/ShippingDataTab';
import MPAndVCTab from './Tabs/MPAndVCTab';

const OrderActions = ({ basePath, data }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
        { data?.status === 'payed' && <RequestShippingButton record={data} /> }
        { data?.status === 'shippingRequested' && <PrintShippingLabels record={data} /> }
    </TopToolbar>
);

const OrderShow = (props) => (
    <Show title={<OrderTitle />} actions={<OrderActions />} {...props}>
        <TabbedShowLayout>
            <Tab label="Main">
                <MainFieldsTab />
            </Tab>
            <Tab label="Details">
                <DetailsTab />
            </Tab>
            <Tab label="Shipping Data">
                <ShippingDataTab />
            </Tab>
            <Tab label="MP / VC">
                <MPAndVCTab />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default OrderShow;
