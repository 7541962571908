import React from 'react';
import {
    TextField,
    SelectField,
    Labeled,
    FunctionField,
} from 'react-admin';

import { Grid, Box, Divider } from '@material-ui/core';
import choices from '../choices';
import DetailedProducts from './DetailedProducts';
import { getPriceTypeLabel } from '../../../helpers/orderHelper';

const ContactPhoneField = (all) => (
    <Labeled label="Teléfono de contacto">
        <TextField source="buyerData.phone" {...all} />
    </Labeled>
);

const DetailsTab = ({ editable = false, record, ...rest }) => {
    const all = { record, ...rest };
    const { invoiceType } = record.buyerData;

    return (
        <>
            <Grid container>
                <Grid item md={6}>
                    <Grid container>
                        <Grid item md={6}>
                            <Box>
                                <Labeled label="Tipo de factura">
                                    <SelectField source="buyerData.invoiceType" choices={choices.invoicesTypes} {...all} />
                                </Labeled>
                                {invoiceType === 'invoiceA' ? (
                                    <>
                                        <Box>
                                            <Labeled label="CUIT">
                                                <TextField source="buyerData.cuit" {...all} />
                                            </Labeled>
                                        </Box>
                                        <Box>
                                            <Labeled label="Razón social">
                                                <TextField source="buyerData.businessName" {...all} />
                                            </Labeled>
                                        </Box>
                                        <Box>
                                            <Labeled label="Condición frente al IVA">
                                                <SelectField source="buyerData.ivaCondition" choices={choices.ivaConditions} {...all} />
                                            </Labeled>
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        <Box>
                                            <Labeled label="Nombre">
                                                <TextField source="buyerData.firstName" {...all} />
                                            </Labeled>

                                        </Box>
                                        <Box>
                                            <Labeled label="Apellido">
                                                <TextField source="buyerData.lastName" {...all} />
                                            </Labeled>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box>
                                {invoiceType === 'invoiceA' ? (
                                    <>
                                        <Box>
                                            <Labeled label="Provincia">
                                                <TextField source="buyerData.province" {...all} />
                                            </Labeled>
                                        </Box>
                                        <Box>
                                            <Labeled label="Localidad">
                                                <TextField source="buyerData.locality" {...all} />
                                            </Labeled>
                                        </Box>
                                        <Box>
                                            <Labeled label="Domicilio comercial">
                                                <TextField source="buyerData.businessAddress" {...all} />
                                            </Labeled>
                                        </Box>
                                        <Box>
                                            <Labeled label="Piso/Depto">
                                                <TextField source="buyerData.floor" {...all} />
                                            </Labeled>
                                        </Box>
                                        <Box>
                                            <ContactPhoneField {...all} />
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        <Box>
                                            <Labeled label="DNI">
                                                <TextField source="buyerData.dni" {...all} />
                                            </Labeled>
                                        </Box>
                                        <Box>
                                            <ContactPhoneField {...all} />
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <Box>
                        <Labeled label="Método de pago">
                            <SelectField source="paymentData.method" choices={choices.paymentMethods} {...all} />
                        </Labeled>
                    </Box>
                    <Box>
                        <Labeled label="Tipo de precio">
                            <FunctionField
                                render={(rec) => getPriceTypeLabel(rec)}
                                {...all}
                            />
                        </Labeled>
                    </Box>
                </Grid>
            </Grid>
            <Divider style={{ marginTop: 32, marginBottom: 16 }} />
            <DetailedProducts editable={editable} {...all} />
        </>
    );
};

export default DetailsTab;
