import React from 'react';
import {
    ReferenceField,
    TextField,
    TextInput,
    SelectField,
    Labeled,
    DateField,
    NumberField,
    FunctionField,
} from 'react-admin';

import {
    Grid, Box, Divider, Tooltip, IconButton,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import choices, { orderStatus, orderCancellationReasons } from '../choices';
import cartHelper from '../../../helpers/cartHelper';
import { SHIPPING_TYPES_LABELS } from '../constants';
import StatusSelect from './StatusSelect';
import { getOrderProductsPriceWithoutIva, getOrderProductsIva } from '../../../helpers/orderHelper';

const DateBox = ({ source, label, ...all }) => (
    <Box>
        <Labeled label={label}>
            <DateField source={source} showTime {...all} />
        </Labeled>
    </Box>
);

const StatusField = ({ status, cancellationReason, ...all }) => (
    <>
        <Box>
            <Labeled label="Estado de la orden">
                <SelectField choices={choices.status} source="status" {...all} />
            </Labeled>
        </Box>
        {status === orderStatus.cancelled && (
            <Box>
                <Labeled label="Motivo de cancelación">
                    <TextField source="cancellationReason" {...all} />
                </Labeled>
            </Box>
        )}
        {cancellationReason === orderCancellationReasons.other && (
            <Box>
                <Labeled label="Detalle del motivo">
                    <TextField source="cancellationReasonDetail" {...all} />
                </Labeled>
            </Box>
        )}
    </>
);

const MainFieldsTab = ({ editable = false, record, ...rest }) => {
    const all = { record, ...rest };
    const { invoiceType } = record.buyerData;
    const { status, cancellationReason } = record;

    return (
        <>
            <Grid container>
                <Box>
                    <Labeled label="Order">
                        <TextField source="code" {...all} />
                    </Labeled>
                </Box>
            </Grid>
            <Grid container>
                <Grid item md={6}>
                    <Grid container>
                        <Grid item md={6}>
                            <Box>
                                {invoiceType === 'invoiceA' ? (
                                    <Labeled label="Razón social">
                                        <TextField source="buyerData.businessName" {...all} />
                                    </Labeled>
                                ) : (
                                    <Labeled label="Nombre">
                                        <TextField source="buyerData.firstName" {...all} />
                                    </Labeled>
                                )}
                            </Box>
                            <Box>
                                <Labeled label="Email">
                                    <ReferenceField reference="users" source="buyer" label="Comprador" link="show" {...all}>
                                        <TextField source="email" />
                                    </ReferenceField>
                                </Labeled>
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box>
                                {invoiceType === 'invoiceA' ? (
                                    <Labeled label="Condición frente al IVA">
                                        <SelectField source="buyerData.ivaCondition" choices={choices.ivaConditions} {...all} />
                                    </Labeled>
                                ) : (
                                    <Labeled label="Apellido">
                                        <TextField source="buyerData.lastName" {...all} />
                                    </Labeled>
                                )}
                            </Box>
                            <Box>
                                {invoiceType === 'invoiceA' ? (
                                    <Labeled label="CUIT">
                                        <TextField source="buyerData.cuit" {...all} />
                                    </Labeled>
                                ) : (
                                    <Labeled label="DNI">
                                        <TextField source="buyerData.dni" {...all} />
                                    </Labeled>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    {editable ? <StatusSelect />
                        : (
                            <StatusField
                                status={status}
                                cancellationReason={cancellationReason}
                                {...all}
                            />
                        )}
                </Grid>
            </Grid>
            <Divider />
            <Grid container>
                <Grid item md={6}>
                    <Grid container>
                        <Grid item md={6}>
                            <Box>
                                <Labeled label={(
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        Subtotal
                                        <Tooltip title="Precio de productos sin IVA ni descuentos">
                                            <IconButton size="small" style={{ marginLeft: 4 }}>
                                                <Info fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                )}
                                >
                                    <FunctionField
                                        render={(rec) => cartHelper
                                            .formatPrice(getOrderProductsPriceWithoutIva(rec))}
                                        {...all}
                                    />
                                </Labeled>
                            </Box>
                            <Box>
                                <Labeled label={(
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        IVA
                                        <Tooltip title="IVA de productos sin descuentos ni envío">
                                            <IconButton size="small" style={{ marginLeft: 4 }}>
                                                <Info fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                )}
                                >
                                    <FunctionField
                                        render={(rec) => cartHelper
                                            .formatPrice(getOrderProductsIva(rec))}
                                        {...all}
                                    />
                                </Labeled>
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            {editable ? <TextInput source="billId" label="N&uacute;mero de factura" /> : (
                                <Labeled label="N° Factura">
                                    <TextField source="billId" {...all} />
                                </Labeled>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={6}>
                            <Box>
                                <Labeled label="Envío">
                                    <NumberField
                                        source="paymentData.shippingPrice"
                                        locales={cartHelper.formaterPesosLocales}
                                        options={{
                                            style: cartHelper.formaterPesosStyle,
                                            currency: cartHelper.formaterPesosCurrency,
                                        }}
                                        {...all}
                                    />
                                </Labeled>
                            </Box>
                            <Box>
                                <Labeled label="Total">
                                    <NumberField
                                        source="totalPrice"
                                        locales={cartHelper.formaterPesosLocales}
                                        options={{
                                            style: cartHelper.formaterPesosStyle,
                                            currency: cartHelper.formaterPesosCurrency,
                                        }}
                                        {...all}
                                    />
                                </Labeled>
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box>
                                <Labeled label="Tipo de envío">
                                    <FunctionField render={(rec) => SHIPPING_TYPES_LABELS[rec.shippingData.shippingType] || '-'} {...all} />
                                </Labeled>
                            </Box>
                            <Box>
                                <Labeled label="Método de pago">
                                    <SelectField source="paymentData.method" choices={choices.paymentMethods} {...all} />
                                </Labeled>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <DateBox label="Fecha de Compra Realizada" source="statusDates.new" {...all} />
                    <DateBox label="Fecha de Pago Aprobado" source="statusDates.payed" {...all} />
                    <DateBox label="Fecha de Solicitud de Envío" source="statusDates.shippingRequested" {...all} />
                    <DateBox label="Fecha de Despacho" source="statusDates.delivering" {...all} />
                    <DateBox label="Fecha de Entrega" source="statusDates.completed" {...all} />
                </Grid>
            </Grid>
        </>
    );
};

export default MainFieldsTab;
