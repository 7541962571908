import React from 'react';
import {
    TextField,
    Labeled,
    FunctionField,
} from 'react-admin';

import { Grid, Box } from '@material-ui/core';
import { SHIPPING_TYPES, SHIPPING_TYPES_LABELS } from '../constants';
import { getOrderShippingTypeColor } from '../../../helpers/orderHelper';

const ShippingDataTab = ({ editable = false, record, ...rest }) => {
    const all = { record, ...rest };
    const { shippingType } = record.shippingData;

    return (
        <>
            <Grid container>
                <Grid item md={6}>
                    <Box>
                        <Labeled label="Tipo de envío">
                            <FunctionField
                                render={(rec) => SHIPPING_TYPES_LABELS[rec.shippingData.shippingType] || '-'}
                                style={{ color: getOrderShippingTypeColor(shippingType) }}
                                {...all}
                            />
                        </Labeled>
                    </Box>
                </Grid>
                <Grid item md={6}>
                    <Labeled label="Número de guía">
                        <TextField source="trackingNumber" {...all} />
                    </Labeled>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item md={6}>
                    <Grid container>
                        <Grid item md={6}>
                            <Labeled label="Nombre">
                                <TextField source="shippingData.firstName" {...all} />
                            </Labeled>
                        </Grid>
                        <Grid item md={6}>
                            <Labeled label="Apellido">
                                <TextField source="shippingData.lastName" {...all} />
                            </Labeled>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <Grid container>
                        <Grid item md={6}>
                            <Labeled label="DNI">
                                <TextField source="shippingData.dni" {...all} />
                            </Labeled>
                        </Grid>
                        <Grid item md={6}>
                            <Labeled label="Teléfono de contacto">
                                <TextField source="shippingData.phone" {...all} />
                            </Labeled>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {[
                SHIPPING_TYPES.homeDelivery,
                SHIPPING_TYPES.pickupAtNearestAgency,
            ].includes(shippingType) && (
                <>
                    <Grid container>
                        <Grid item md={6}>
                            <Box display="flex" flexDirection="column">
                                <Labeled label="Localidad">
                                    <TextField source="shippingData.locality" {...all} />
                                </Labeled>
                                <Labeled label={`Dirección${shippingType === SHIPPING_TYPES.pickupAtNearestAgency ? ' (de la agencia)' : ''}`}>
                                    <TextField source="shippingData.address" {...all} />
                                </Labeled>
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box display="flex" flexDirection="column">
                                <Labeled label="Provincia">
                                    <TextField source="shippingData.province" {...all} />
                                </Labeled>
                                {shippingType === SHIPPING_TYPES.homeDelivery && (
                                    <Labeled label="Piso">
                                        <TextField source="shippingData.floor" {...all} />
                                    </Labeled>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                    {shippingType === SHIPPING_TYPES.homeDelivery && (
                        <Grid container>
                            <Labeled label="Código Postal">
                                <TextField source="shippingData.codigoPostal" {...all} />
                            </Labeled>
                        </Grid>
                    )}
                </>
            )}
        </>
    );
};

export default ShippingDataTab;
