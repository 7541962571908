import React from 'react';
import {
    BooleanInput,
    Edit, FormDataConsumer,
    FormTab, ImageField, ImageInput,
    NumberInput,
    SelectInput,
    TabbedForm,
    TextInput,
    ArrayInput,
    SimpleFormIterator,
    AutocompleteInput,
    SimpleShowLayout,
} from 'react-admin';
import { required } from 'ra-core';
import { Box } from '@material-ui/core';
import RichTextInput from 'ra-input-rich-text';
import ProductTitle from './ProductTitle';
import choices from './choices';
import ProductSpecsInput from './ProductSpecsInput';
import { convertToInteger } from '../../helpers/mathHelper';
import { ConfigIds } from '../../helpers/types';
import GlobalTaxView from './GlobalTaxView';
import useProduct from './useProduct';
import { inputStyles } from './styles';
import { validateImagesLength } from './utils';
import ProductSEO from './ProductSEO';

export default (props) => {
    const {
        brands, providers, classes, dataTaxesConfig, onFailure,
    } = useProduct();

    return (
        <Edit undoable={false} onFailure={onFailure} title={<ProductTitle />} {...props}>
            <TabbedForm>
                <FormTab label="Main">
                    <SelectInput disabled source="type" choices={choices.product_types} />
                    <TextInput source="name" validate={[required()]} style={inputStyles} />
                    <TextInput source="slug" style={inputStyles} helperText="Dejar en blanco para generar automáticamente" />
                    <Box style={{ display: 'flex', gap: 10, width: '522px' }}>
                        <TextInput style={{ width: '256px' }} source="code" validate={[required()]} />
                        <TextInput style={{ width: '256px' }} source="gtin" />
                    </Box>
                    <SelectInput
                        resettable
                        source="warranty"
                        choices={choices.warranty}
                    />
                    <AutocompleteInput
                        source="brand"
                        choices={brands}
                        validate={[required()]}
                    />
                    <RichTextInput
                        source="description"
                        label="Descripcion"
                        validate={[required()]}
                    />
                    <NumberInput source="weight" validate={[required()]} />
                    <ArrayInput source="tags" label="Tags">
                        <SimpleFormIterator inline>
                            <TextInput />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <BooleanInput source="published" defaultValue />
                    <BooleanInput source="hideOnProbuilder" label="Ocultar en ProBuilder" defaultValue={false} />
                    <BooleanInput source="hideOnEcommerce" label="Ocultar en Ecommerce" defaultValue={false} />
                </FormTab>
                <FormTab label="Images">
                    <ImageInput
                        source="images"
                        multiple
                        validate={validateImagesLength}
                    >
                        <ImageField source="url" title="title" />
                    </ImageInput>
                </FormTab>
                <FormTab label="Pricing & Stock">
                    <NumberInput label="Costo USD" source="pricing.cost" min="0" validate={[required()]} />
                    <SelectInput label="IVA" source="pricing.iva" defaultValue={choices.iva[0]} choices={choices.iva} />
                    <>
                        <NumberInput label="Precio USD" source="price.special.USD" min="0" disabled />
                        <NumberInput label="Precio ARS" source="price.special.ARS" min="0" disabled />
                        <NumberInput label="Precio tachado ARS" source="price.special.strikethrough" parse={convertToInteger} style={{ width: '250px' }} />
                    </>
                    <>
                        <NumberInput label="Precio USD (lista)" source="price.list.USD" min="0" disabled />
                        <NumberInput label="Precio ARS (lista)" source="price.list.ARS" min="0" disabled />
                    </>
                    <TextInput source="customLabel" label="Etiqueta" />
                    <Box style={{ display: 'flex', gap: 10 }}>
                        <NumberInput source="stock" validate={[required()]} parse={convertToInteger} />
                        <SelectInput label="Proveedor" source="provider" choices={providers} />
                    </Box>
                </FormTab>
                <FormTab label="Custom Pricing">
                    <BooleanInput label="Custom Pricing" source="pricing.hasCustomPricing" defaultValue={false} helperText="Estos impuestos pisaran los parámetros globales actuales" />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData.pricing.hasCustomPricing
                        && (
                            <SimpleShowLayout className={classes.conditionalCustomPricingField}>
                                <>
                                    <NumberInput source={`pricing.customPricing.${ConfigIds.PRICING_IIBB}`} label="Ingresos Brutos" validate={required()} defaultValue={dataTaxesConfig[ConfigIds.PRICING_IIBB]} {...rest} />
                                    <NumberInput source={`pricing.customPricing.${ConfigIds.PRICING_COM_BANCARIA}`} label="Comisión bancaria" validate={required()} defaultValue={dataTaxesConfig[ConfigIds.PRICING_COM_BANCARIA]} {...rest} />
                                    <NumberInput source={`pricing.customPricing.${ConfigIds.PRICING_G_ADM}`} label="Gastos Administrativos" validate={required()} defaultValue={dataTaxesConfig[ConfigIds.PRICING_G_ADM]} {...rest} />
                                    <NumberInput source={`pricing.customPricing.${ConfigIds.PRICING_MARKUP}`} label="Markup" validate={required()} defaultValue={dataTaxesConfig[ConfigIds.PRICING_MARKUP]} {...rest} />
                                    <NumberInput source={`pricing.customPricing.${ConfigIds.PRICING_CMP}`} label="Comisión Mercado Pago" validate={required()} defaultValue={dataTaxesConfig[ConfigIds.PRICING_CMP]} {...rest} />
                                </>
                                <>
                                    <NumberInput source={`pricing.customPricing.${ConfigIds.INTEREST_RATE}`} label="Interés de la Cuota" validate={required()} defaultValue={dataTaxesConfig[ConfigIds.INTEREST_RATE]} {...rest} />
                                    <NumberInput source={`pricing.customPricing.${ConfigIds.PRICING_COM_BANCARIA_PRECIO_LISTA}`} label="Comisión bancaria (precio de lista)" validate={required()} style={{ width: '280px' }} defaultValue={dataTaxesConfig[ConfigIds.PRICING_COM_BANCARIA_PRECIO_LISTA]} {...rest} />
                                </>
                            </SimpleShowLayout>
                        )}
                    </FormDataConsumer>
                    <GlobalTaxView />
                </FormTab>
                <FormTab label="Specifications">
                    <ProductSpecsInput />
                </FormTab>
                <FormTab label="SEO">
                    <ProductSEO />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};
