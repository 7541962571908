import * as React from 'react';
import {
    Datagrid,
    EditButton,
    List,
    ShowButton,
    TextField,
    DateField,
    SelectField,
    NumberField,
    Link,
    useGetOne,
    LinearProgress,
} from 'react-admin';

import OrderFilter from './OrderFilter';
import choices from './choices';
import cartHelper from '../../helpers/cartHelper';

const BuyerField = ({ record }) => {
    const { data, isLoading, error } = useGetOne('users', record.buyer.toString());

    if (isLoading) return <LinearProgress />;
    if (error) return <></>;

    return (
        <Link to={`/users/${record.buyer}/show`}>
            {data ? data.email : 'Comprador no encontrado'}
        </Link>
    );
};

export default (props) => (
    <List {...props} filters={<OrderFilter />} sort={{ field: 'statusDates.new', order: 'DESC' }} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="code" label="C&oacute;digo" />
            <TextField source="billId" label="N° Factura" />
            <BuyerField label="Comprador" />
            <SelectField choices={choices.status} source="status" />
            <NumberField
                source="totalPrice"
                locales={cartHelper.formaterPesosLocales}
                options={{
                    style: cartHelper.formaterPesosStyle,
                    currency: cartHelper.formaterPesosCurrency,
                }}
            />
            <DateField source="statusDates.new" label="Fecha de Compra Realizada" showTime />
            <EditButton basePath="/orders" />
            <ShowButton basePath="/orders" />
        </Datagrid>
    </List>
);
